@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .hamburgerButton.open span:first-child {
        transform: rotate(45deg);
        top: 10px;      
    }
    .hamburgerButton.open span:nth-child(2) {
      opacity: 0;
    }
    .hamburgerButton.open span:last-child {
        transform: rotate(-45deg);
        bottom: 4px;
    }
    
    .active {
    text-decoration: underline; 
    text-underline-offset: 8px;
    color:#FFEA9D
    }
    .active1 {
        color:#FFEA9D;
        @media (min-width: 1024px) {
                font-size: 1.2rem; 
        }
    }
    .pause-on-hover:hover {
        animation-play-state: paused;
    }

    .resume-on-hover:not(:hover) {
        animation-play-state: running;
    }
    .perspective {
        perspective: 100px;
    }
    
}
@layer base {
    @font-face {
        font-family: 'OrkneyBold';
        src: url('./Fonts/Orkney/Orkney-Bold.ttf') format('truetype');
        /* font-weight: normal; */
    }
    @font-face {
        font-family: 'OrkneyRegular';
        src: url('./Fonts/Orkney/Orkney-Regular.ttf') format('truetype');
        /* font-weight: normal; */
    }
    @font-face {
        font-family: 'OrkneyLight';
        src: url('./Fonts/Orkney/Orkney-Light.ttf') format('truetype');
        /* font-weight: normal; */
    }

    @font-face {
        font-family: 'LibreRegular';
        src: url('./Fonts/Libre/Libre-Regular.ttf') format('truetype');
        /* font-weight: normal; */
    }
    @font-face {
        font-family: 'LibreBold';
        src: url('./Fonts/Libre/Libre-Bold.ttf') format('truetype');
        /* font-weight: normal; */
    }
    @font-face {
        font-family: 'LibreItalic';
        src: url('./Fonts/Libre/Libre-Italic.ttf') format('truetype');
        /* font-weight: normal; */
    }
}